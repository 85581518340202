export const textEn = {
    lang: 'En',
    service: 'About',
    title: 'JOIN THE TRAINING!',
    subtitle: 'SportUp is a cloud tracker of joint remote training and competitions in real time',
    download: 'Download the app',
    aboutH1: 'Create a workout!',
    aboutText1: "If you haven't found an event that suits you, create your own training or competition.",
    aboutH2:'Invite your friends!',
    aboutText2: 'Invite friends via social networks, mail, SMS, messengers.',
    aboutH3: 'Communicate!',
    aboutText3: 'Communicate by voice, video, text during training or while waiting.',
    aboutH4: 'Join the training!',
    aboutText4: 'Choose an event with the desired sport and distance, tap and you will become a participant in this event.',
    sports: 'TYPES OF SPORTS',
    sport1: 'Running',
    sport2: 'Gym',
    sport3: 'Bicycle',
    sport4: 'Ski',
    sport5: 'Walking',
    sport6: 'Skateboard',
    sport7: 'Water sports',
    sport8: 'Skates',
    sportImage: 'Map',
    title2: 'SportUp',
    subtitle2: 'You can run in Moscow, and I in Perm, Eliud Kipchoge in Berlin - and all at the same time, communicating or silently, lagging behind and overtaking each other.',
    subtitle3: 'Quick search for training sessions, partners and starts!',
    deleteAccountMenu: 'Delete account',
    deleteTitle: 'Delete your Account',
    deleteAccountText: 'You can delete your Account at any time. If you change your mind, you not be able to recover it.',
    deleteAccountStep1: 'Open mobile app SportUp.',
    deleteAccountStep2: 'Select "More" in menu.',
    deleteAccountStep3: 'Click icon "Remove account".',
    deleteAccountStep4: 'Confirm deleting.',
    subscription: 'Subscription',
    subscriptionButton: 'Subscribe',
    subscriptionTariff1: 'Basic',
    subscriptionPrice1: 'Free',
    subscriptionTariff1Desc1: 'Access to all training plans and workouts',
    subscriptionTariff1Desc2: 'Connecting to an external bluetooth heart rate monitor',
    subscriptionTariff1Desc3: 'The ability to create private events that can be accessed only by invitation',
    subscriptionTariff1Button: 'Joining us is free',
    subscriptionTariff2: 'Premium',
    subscriptionPrice2: '$4.3/month',
    subscriptionTariff2Desc1: 'Access to all training plans and workouts',
    subscriptionTariff2Desc2: 'Connecting to an external bluetooth heart rate monitor',
    subscriptionTariff2Desc3: 'The ability to create private events that can be accessed only by invitation',
    subscriptionTariff2Desc4: 'High-quality video generation (live tracks)',
    subscriptionTariff2Desc5: 'Autopause - turning off the stopwatch in the absence of movement of the athlete (allows you to significantly improve the result in an urban environment at the intersection of roads and traffic lights)',
    subscriptionTariff2Button: 'Subscribe for $4.3/month',
    login: "Login",
    popupTitle: "Login",
    popupBtnVk: "VKontakte",
    popupBtnGoogle: "Google",
};