export const textRu = {
    lang: 'Ru',
    service: 'О сервисе',
    title: 'ПРИСОЕДИНИСЬ К ТРЕНИРОВКЕ!',
    subtitle: 'SportUp - облачный трекер совместных удалённых тренировок и соревнований в реальном масштабе времени',
    download: 'Скачать приложение',
    aboutH1: 'Создай тренировку!',
    aboutText1: 'Если, Вы не нашли устраивающее Вас событие - создайте свою тренировку или соревнование.',
    aboutH2:'Приглашай друзей!',
    aboutText2: 'Приглашай друзей через социальные сети, почту, смс, мессенджеры.',
    aboutH3: 'Общайся!',
    aboutText3: 'Общайся голосом, видео, текстом во время тренировки или во время ожидания.',
    aboutH4: 'Присоединись к тренировке!',
    aboutText4: 'Выберете событие с нужным видом спорта и расстоянием, тапните и Вы станете участником этого события.',
    sports: 'ВИДЫ СПОРТА',
    sport1: 'Бег',
    sport2: 'Спортзал',
    sport3: 'Велосипед',
    sport4: 'Лыжи',
    sport5: 'Ходьба',
    sport6: 'Скейтборд',
    sport7: 'Водный спорт',
    sport8: 'Коньки',
    sportImage: 'Карта',
    title2: 'SportUp',
    subtitle2: 'Вы можете бежать в Москве, а я в Перми, Элиуд Кипчоге в Берлине - и все одновременно, общаясь или молча, отставая и обгоняя друг друга.',
    subtitle3: 'Быстрый поиск тренировок, партнёров и стартов!',
    deleteAccountMenu: 'Удалить аккаунт',
    deleteTitle: 'Как удалить аккаунт',
    deleteAccountText: 'Удалить аккаунт можно в любой момент. Учтите, что мы не сможем восстановить его, если вы передумаете.',
    deleteAccountStep1: 'Откройте мобильное приложение SportUp.',
    deleteAccountStep2: 'Выберете пункт "Ещё" в меню.',
    deleteAccountStep3: 'Нажмите на иконку удаления аккаунта.',
    deleteAccountStep4: 'Подтвердите удаление.',
    subscription: 'Подписка',
    subscriptionButton: 'Оформить подписку',
    subscriptionTariff1: 'Базовая',
    subscriptionPrice1: 'Free',
    subscriptionTariff1Desc1: 'Доступ ко всем тренировочным планам и тренировкам',
    subscriptionTariff1Desc2: 'Подключение к внешним bluetooth пульсометрам',
    subscriptionTariff1Desc3: 'Возможность создания приватных событий, доступ к которым возможен только по приглашению',
    subscriptionTariff1Button: 'Присоединяйться к нам бесплатно',
    subscriptionTariff2: 'Premium',
    subscriptionPrice2: '400 руб./мес',
    subscriptionTariff2Desc1: 'Доступ ко всем тренировочным планам и тренировкам',
    subscriptionTariff2Desc2: 'Подключение к внешним bluetooth пульсометрам',
    subscriptionTariff2Desc3: 'Возможность создания приватных событий, доступ к которым возможен только по приглашению',
    subscriptionTariff2Desc4: 'Генерация видео (живых треков) в высоком качестве',
    subscriptionTariff2Desc5: 'Автопауза - отключение секундомера при отсутствие перемещения спортсмена (позволяет значительно улучшать результат в условиях городской среды при пересечении дорог и светофоров)',
    subscriptionTariff2Button: 'Оформить подписку за 400 руб./мес',
    login: "Вход",
    popupTitle: "Вход",
    popupBtnVk: "ВКонтакте",
    popupBtnGoogle: "Google",
};